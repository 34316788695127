<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="500px"
    transition="dialog-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn color="color3" text v-on="on">
        round copier
      </v-btn>
    </template>
    <v-card>
      <v-toolbar color="color2 color2Text--text">
        <v-toolbar-title>Round Copier</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          color="color1 color1Text--text"
          small fab
          @click.stop="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-select
          :items="divisions"
          v-model="selectedDivision"
          item-text="name"
          return-object
          label="Division to copy"
          color="color3"
          item-color="color3"
        ></v-select>
        <v-expand-transition>
          <v-select
            v-if="copyable"
            :items="copyable"
            v-model="roundsToCopy"
            item-text="name"
            label="Rounds to copy"
            color="color3"
            item-color="color3"
            return-object
            chips multiple clearable deletable-chips
          ></v-select>
        </v-expand-transition>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          color="success"
          :disabled="roundsToCopy.length === 0"
          @click.stop="go"
        >GO</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import max from 'lodash.max'

export default {
  props: ['tournament', 'division'],
  data () {
    return {
      dialog: false,
      selectedDivision: null,
      roundsToCopy: []
    }
  },
  computed: {
    divisions () {
      return this.tournament.divisions.filter(f => f.days.length > 1 && f.id !== this.division.id)
    },
    maxRound () {
      return max(this.division.days.map(m => m.number))
    },
    copyable () {
      return this.selectedDivision && this.maxRound && this.selectedDivision.days.filter(f => f.number > this.maxRound)
    }
  },
  methods: {
    go () {
      this.$emit('copy-us', this.roundsToCopy)
      this.dialog = false
    }
  }
}
</script>
